// @flow
import React from 'react';
import Helmet from 'react-helmet';
import type { Node as ReactNode } from 'react';
import styles from './Layout.module.scss';

type Props = {
  children: ReactNode,
  title: string,
  description?: string,
  tags?: [string]
};

const Layout = ({ children, title, description, tags }: Props) => (
  <div className={styles.layout}>
    <Helmet>
      <html lang="fr" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={tags && tags.join(", ")} />
    </Helmet>
    {children}
  </div>
);

export default Layout;
