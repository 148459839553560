// @flow
import React, { useState } from 'react';
import styles from './LeadMagnet.module.scss';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import swal from 'sweetalert';

type Props = {
  isInIndex: Boolean
};

const LeadMagnet = ({isInIndex}: Props) => {
  const [email, setEmail] = useState('');
  const [dismissed, setDismissed] = useState(false)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    addToMailchimp(email)
      .then((data) => {
        swal("Merci !", "Vous allez recevoir le guide par email", "success");
      })
      .catch((error: Error) => {
        swal("Erreur...", "Il y a eu un problème... Veuillez réessayer plus tard", "error");
      });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  const handleDismissClicked = (event: React.ChangeEvent<HTMLDivElement>) => {
    setDismissed(true)
  };

  let style = dismissed ? styles['dismissed'] : (isInIndex ? styles['indexLead'] : styles['lead'])

  return (
    <div className={style}>
        <form onSubmit={handleSubmit} className={styles.EmailListForm}>
            {/* {!isInIndex && <div className={styles.dismiss} onClick={handleDismissClicked}>X</div>} */}
            <p>Entre ton email pour recevoir <strong>le guide complet pour apprendre Swift</strong> peu importe ton niveau</p>
            <div className={styles.Wrapper}>
                <input
                    placeholder="Email"
                    name="email"
                    type="text"
                    onChange={handleEmailChange}
                />
                <button type="submit">Envoyer</button>
            </div>
        </form>
    </div>
  );
};

export default LeadMagnet;